<pc-overlay
  [config]="{
    title: 'Edit Charge Point Settings'
  }"
  (closeClick)="ocppProxy.showEditChargePointOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->

    <form [formGroup]="editChargePointForm" style="display: block; width: 100%">
      <pc-input-textfield
        *ngIf="false"
        [control]="editChargePointAliasControl"
        [placeholder]="'Charge Point Alias'"
        variant="fit-width"
        label="Charge Point Alias"></pc-input-textfield>
      <pc-input-number
        [control]="editChargePointConnectorCountControl"
        [placeholder]="'Connector Count'"
        variant="fit-width"
        label="Connector Count"></pc-input-number>
    </form>
  </ng-container>

  <ng-container buttons>
    <pc-button id="save" size="small" [text]="'SHARED.SAVE' | translate" (btnClick)="save(); $event.stopPropagation()"></pc-button>
  </ng-container>
</pc-overlay>
