<pc-overlay
  [config]="{
    title: 'Add Backend provider'
  }"
  (closeClick)="ocppProxy.showAssignBackendProviderOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->
    <form [formGroup]="assignBackendProviderForm" style="width: 100%">
      <flex-col *ngFor="let field of fields" style="width: 100%">
        <div class="form-input">
          <pc-input-textfield
            *ngIf="field.type === 'text'"
            [id]="field.inputId"
            [control]="field.control"
            [placeholder]="field.placeholder"
            variant="fit-width"></pc-input-textfield>
          <pc-input-number
            *ngIf="field.type === 'number'"
            [id]="field.inputId"
            [control]="field.control"
            [placeholder]="field.placeholder"
            variant="fit-width"></pc-input-number>
          <pc-input-select *ngIf="field.type === 'select'" [config]="field" [form]="assignBackendProviderForm"></pc-input-select>
        </div>
      </flex-col>

      <!-- content projection: buttons -->
      <pc-button [disabled]="assignBackendProviderForm.invalid" [text]="'OCPP.ASSIGN_BACKEND_PROVIDER' | translate" (btnClick)="submit()"></pc-button>
    </form>
  </ng-container>
</pc-overlay>
