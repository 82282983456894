<pc-overlay
  [config]="{
    title: 'Edit Backend Provider Settings'
  }"
  (closeClick)="ocppProxy.showChargePointBackendProviderEditOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->

    <form [formGroup]="editBackendForm" style="display: block; width: 100%">
      <pc-input-textfield
        [control]="editBackendAliasControl"
        [placeholder]="'Charge Point Alias'"
        variant="fit-width"
        label="Charge Point Alias"></pc-input-textfield>
    </form>
  </ng-container>

  <ng-container buttons>
    <pc-button id="save" size="small" [text]="'SHARED.SAVE' | translate" (btnClick)="save(); $event.stopPropagation()"></pc-button>
  </ng-container>
</pc-overlay>
