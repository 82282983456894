import { Component, Input, OnInit } from '@angular/core'
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ChargePoint, ChargePointBackendProvider } from '../../services/ocpp-proxy.interfaces'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-charge-backend-edit-modal',
  templateUrl: './ocpp-proxy-charge-backend-edit-modal.component.html',
  styleUrls: ['./ocpp-proxy-charge-backend-edit-modal.component.scss'],
})
export class OcppProxyChargeBackendEditModalComponent implements OnInit {
  @Input()
  chargePoint: ChargePoint

  @Input()
  backendProvider: ChargePointBackendProvider

  editBackendForm: UntypedFormGroup

  editBackendAliasControl: FormControl

  constructor(public ocppProxy: OcppProxyService) {}

  ngOnInit(): void {
    this.editBackendAliasControl = new UntypedFormControl(this.backendProvider.aliasEndpointId || '', [Validators.maxLength(256)])
    this.editBackendForm = new UntypedFormGroup({ backendAlias: this.editBackendAliasControl })
  }

  save() {
    this.backendProvider.aliasEndpointId = this.editBackendForm.get('backendAlias')!.value
    this.ocppProxy.showChargePointBackendProviderEditOverlay$.next(false)
    this.ocppProxy.editChargePoint(this.chargePoint, true)
  }
}
