import { Component, Input } from '@angular/core'
import { BackendProvider, ChargePoint } from '../../services/ocpp-proxy.interfaces'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-charge-point-assign-permission-modal',
  templateUrl: './ocpp-proxy-charge-point-assign-permission-modal.component.html',
  styleUrls: ['./ocpp-proxy-charge-point-assign-permission-modal.component.scss'],
})
export class OcppProxyChargePointAssignPermissionModalComponent {
  @Input()
  chargePoint: ChargePoint

  @Input()
  backendProvider: BackendProvider

  constructor(public ocppProxy: OcppProxyService) {}
}
