<pc-overlay
  *ngIf="true"
  [config]="{
    title: 'Edit Backend provider'
  }"
  (closeClick)="ocppProxy.showEditBackendProviderOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->
    <form [formGroup]="editBackendProviderForm" style="width: 100%">
      <flex-col *ngFor="let field of fields" style="width: 100%">
        <div class="form-input">
          <pc-input-textfield [id]="field.inputId" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
        </div>
      </flex-col>
    </form>
  </ng-container>
  <ng-container buttons>
    <!-- content projection: buttons -->
    <pc-button [disabled]="editBackendProviderForm.invalid" size="small" [text]="'SHARED.SAVE' | translate" (btnClick)="submit()"></pc-button>
  </ng-container>
</pc-overlay>
