<pc-overlay
  [config]="{
    title: 'Edit Backend Provider Permissions'
  }"
  (closeClick)="ocppProxy.showAssignChargePointBackendProviderPermissionOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->
    <span style="margin-bottom: 16px">The Backend Provider is allowed to sent the following events if activated. All disabled events will be blocked.</span>
    <flex-col class="backendProviderPermission">
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'RemoteStartTransaction')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'RemoteStartTransaction')">
        RemoteStartTransaction
      </mat-slide-toggle>
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'RemoteStopTransaction')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'RemoteStopTransaction')">
        RemoteStopTransaction
      </mat-slide-toggle>
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'RemoteUnlockConnector')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'RemoteUnlockConnector')">
        RemoteUnlockConnector
      </mat-slide-toggle>
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'Reset')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'Reset')">
        Reset
      </mat-slide-toggle>
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'ClearCache')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'ClearCache')">
        ClearCache
      </mat-slide-toggle>
      <mat-slide-toggle
        class="permissionToggle"
        [color]="'accent'"
        [checked]="ocppProxy.isPermissionSet(chargePoint, backendProvider, 'GetConfiguration')"
        [disabled]="false"
        (click)="ocppProxy.togglePermission(chargePoint, backendProvider, 'GetConfiguration')">
        GetConfiguration
      </mat-slide-toggle>
    </flex-col>
  </ng-container>
</pc-overlay>
