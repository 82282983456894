import { Component } from '@angular/core'
import { ProficloudService } from '@services/proficloud.service'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-router',
  templateUrl: './ocpp-proxy-router.component.html',
  styleUrls: ['./ocpp-proxy-router.component.scss'],
})
export class OcppProxyRouterComponent {
  constructor(
    public ocppProxy: OcppProxyService,
    public proficloud: ProficloudService
  ) {}
}
