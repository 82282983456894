<pc-overlay
  *ngIf="true"
  [config]="{
    title: 'Add Backend provider'
  }"
  (closeClick)="ocppProxy.showAddBackendProviderOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->
    <form [formGroup]="addBackendProviderForm">
      <flex-col style="width: 100%; margin-top: 20px">
        <div class="form-input">
          <pc-input-textfield
            id="add-backend-provider-name"
            [control]="providerNameControl"
            [placeholder]="'Backend Provider Name'"
            variant="fit-width"
            label="Backend Provider Name"></pc-input-textfield>
        </div>
        <div class="form-input">
          <pc-input-textfield
            id="add-backend-provider-url"
            [control]="backendURLControl"
            [placeholder]="'Backend URL'"
            variant="fit-width"
            label="Backend URL"></pc-input-textfield>
        </div>
      </flex-col>
    </form>
  </ng-container>
  <ng-container buttons>
    <!-- content projection: buttons -->
    <pc-button [disabled]="!addBackendProviderForm.valid" size="small" [text]="'OCPP.ADD_BACKEND_PROVIDER' | translate" (btnClick)="submit()"></pc-button>
  </ng-container>
</pc-overlay>
