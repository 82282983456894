<div class="container">
  <router-outlet></router-outlet>
</div>

<div *ngIf="ocppProxy.showAddChargePointOverlay$ | async as chargePoint">
  <app-ocpp-proxy-add-charge-point-modal></app-ocpp-proxy-add-charge-point-modal>
</div>

<div *ngIf="ocppProxy.showAddBackendProviderOverlay$ | async as backendProvider">
  <app-ocpp-proxy-add-backend-provider-modal></app-ocpp-proxy-add-backend-provider-modal>
</div>

<div *ngIf="ocppProxy.showEditBackendProviderOverlay$ | async as backendProvider">
  <app-ocpp-proxy-edit-backend-provider-modal [backendProvider]="backendProvider"></app-ocpp-proxy-edit-backend-provider-modal>
</div>

<div *ngIf="ocppProxy.showAssignBackendProviderOverlay$ | async as chargePoint">
  <app-ocpp-proxy-assign-backend-provider-modal [chargePoint]="chargePoint"></app-ocpp-proxy-assign-backend-provider-modal>
</div>

<div *ngIf="ocppProxy.showAssignChargePointBackendProviderPermissionOverlay$ | async as data">
  <app-ocpp-proxy-charge-point-assign-permission-modal
    [chargePoint]="data.chargePoint"
    [backendProvider]="data.backendProvider"></app-ocpp-proxy-charge-point-assign-permission-modal>
</div>

<div *ngIf="ocppProxy.showChargePointBackendProviderEditOverlay$ | async as data">
  <app-ocpp-proxy-charge-backend-edit-modal
    [chargePoint]="data.chargePoint"
    [backendProvider]="data.backendProvider"></app-ocpp-proxy-charge-backend-edit-modal>
</div>

<div *ngIf="ocppProxy.showEditChargePointOverlay$ | async as data">
  <app-ocpp-proxy-charge-point-edit-modal [chargePoint]="data"></app-ocpp-proxy-charge-point-edit-modal>
</div>
