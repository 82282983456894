<pc-overlay
  [config]="{
    title: 'Edit Backend Provider Settings'
  }"
  (closeClick)="ocppProxy.showAddChargePointOverlay$.next(false)">
  <!-- dialog body -->
  <ng-container body>
    <form [formGroup]="addChargePointForm" style="width: 100%">
      <flex-col *ngFor="let field of fields" style="width: 100%">
        <div class="form-input">
          <pc-input-textfield
            *ngIf="field.type === 'text'"
            [id]="field.inputId"
            [control]="field.control"
            [placeholder]="field.placeholder"
            variant="fit-width"></pc-input-textfield>
          <pc-input-number
            *ngIf="field.type === 'number'"
            [id]="field.inputId"
            [control]="field.control"
            [placeholder]="field.placeholder"
            variant="fit-width"></pc-input-number>
          <pc-input-select *ngIf="field.type === 'select'" [config]="field" [form]="addChargePointForm"></pc-input-select>
        </div>
      </flex-col>
    </form>
  </ng-container>
  <ng-container buttons>
    <!-- content projection: buttons -->
    <pc-button [disabled]="addChargePointForm.invalid" size="small" [text]="'OCPP.ADD_CHARGE_POINT' | translate" (btnClick)="submit()"></pc-button>
  </ng-container>
</pc-overlay>
