import { Component, Input, OnInit } from '@angular/core'
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ChargePoint } from '../../services/ocpp-proxy.interfaces'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-charge-point-edit-modal',
  templateUrl: './ocpp-proxy-charge-point-edit-modal.component.html',
  styleUrls: ['./ocpp-proxy-charge-point-edit-modal.component.scss'],
})
export class OcppProxyChargePointEditModalComponent implements OnInit {
  @Input()
  chargePoint: ChargePoint

  editChargePointForm: UntypedFormGroup

  editChargePointAliasControl: FormControl

  editChargePointConnectorCountControl: FormControl

  constructor(public ocppProxy: OcppProxyService) {}

  ngOnInit(): void {
    this.editChargePointAliasControl = new UntypedFormControl(this.chargePoint.aliasEndpointId || '', [Validators.maxLength(256)])
    this.editChargePointConnectorCountControl = new UntypedFormControl(this.chargePoint.connectorCount, [Validators.max(10), Validators.min(1)])
    this.editChargePointForm = new UntypedFormGroup({
      chargePointAlias: this.editChargePointAliasControl,
      chargePointConnectorCount: this.editChargePointConnectorCountControl,
    })
  }

  save() {
    this.chargePoint.connectorCount = this.editChargePointForm.get('chargePointConnectorCount')!.value as number
    this.ocppProxy.showEditChargePointOverlay$.next(false)
    this.ocppProxy.editChargePoint(this.chargePoint, false)
  }
}
