import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ProficloudInputConfig } from 'src/app/modules/proficloud/services/proficloud.interfaces'
import { UiService } from 'src/app/modules/proficloud/services/ui/ui.service'
import { ChargePoint } from '../../services/ocpp-proxy.interfaces'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-assign-backend-provider-modal',
  templateUrl: './ocpp-proxy-assign-backend-provider-modal.component.html',
  styleUrls: ['./ocpp-proxy-assign-backend-provider-modal.component.scss'],
})
export class OcppProxyAssignBackendProviderModalComponent implements OnInit {
  fields: ProficloudInputConfig[]

  assignBackendProviderForm: UntypedFormGroup

  selects: any[]

  @Input()
  chargePoint: ChargePoint

  constructor(
    public ui: UiService,
    public ocppProxy: OcppProxyService
  ) {}

  ngOnInit(): void {
    this.createFields()
    this.assignBackendProviderForm = new UntypedFormGroup(this.ui.formFieldsToObject(this.fields))
  }

  getBackendProviderSelect(): any {
    this.selects = []
    this.ocppProxy.backend_providers.forEach((backend_provider) => {
      this.selects.push({
        key: backend_provider.backendProviderId,
        value: backend_provider.backendProviderName,
        visible: () => true,
      })
    })
  }

  createFields() {
    this.getBackendProviderSelect()
    this.fields = [
      {
        type: 'number',
        placeholder: 'Backend Provider Priority',
        helpText: 'e.g. 2',
        icon: 'label',
        value: '',
        control: new UntypedFormControl('', [Validators.maxLength(80), Validators.required]),
        key: 'backend_provider_priority',
        inputId: 'assign-charge-point-backend-provider-priority',
      },
      {
        type: 'select',
        selectValues: this.selects,
        placeholder: 'Backend Provider',
        helpText: 'e.g. Steve OCPP Backend',
        value: '',
        control: new UntypedFormControl('', [Validators.required]),
        key: 'backend_provider_id',
        inputId: 'assign-charge-point-backend-provider',
      },
      {
        type: 'text',
        placeholder: 'Backend Provider Username',
        helpText: 'e.g. 4e40b9d7-36ba-4abb-a106-567259f37903',
        icon: 'label',
        value: '',
        control: new UntypedFormControl('', [Validators.maxLength(80)]),
        key: 'backend_provider_username',
        inputId: 'assign-charge-point-backend-provider-username',
      },
      {
        type: 'text',
        placeholder: 'Backend Provider Password',
        helpText: 'e.g. 4e40b9d7-36ba-4abb-a106-567259f37903',
        icon: 'label',
        value: '',
        control: new UntypedFormControl('', [Validators.maxLength(80)]),
        key: 'backend_provider_password',
        inputId: 'assign-charge-point-backend-provider-password',
      },
    ]
  }

  clearForm() {
    this.fields.forEach((field) => (field.value = ''))
  }

  submit() {
    const prioField = this.fields.find((field) => field.key === 'backend_provider_priority')

    if (prioField) {
      const backend_provider_priority = Number(prioField.value)
      const idField = this.fields.find((field) => field.key === 'backend_provider_id')

      if (idField) {
        const backend_provider_id = idField.value as string

        this.ocppProxy.addChargePointBackendProviderToList(this.chargePoint, backend_provider_priority, backend_provider_id)

        this.clearForm()
        this.ocppProxy.showAssignBackendProviderOverlay$.next(false)
      } else {
        throw new Error('No field Key matches given backend_provider_id')
      }
    } else {
      throw new Error('No field Key matches given backend_provider_priority')
    }
  }
}
